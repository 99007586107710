
import { URLS } from '@/common/js/constant/index'
import KlookIcon from '@/components/icon.vue'
import { get } from '@/plugins'
import { reactive, toRefs, defineComponent, watch, onBeforeMount, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { LoadingOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    KlookIcon,
    LoadingOutlined
  },

  setup() {
    const router = useRouter()
    const { t } = useI18n({})
    const route = useRoute()
    const state: {
      status: string
      seconds: number
      tokenInfo: any
    } = reactive({
      status: 'loading',
      seconds: 3,
      tokenInfo: {
        token: '',
        token_type: '', // active、forget、appoint
        login_type: '', // email、phone
        username: ''
      }
    })

    const getVerifyDes = () => {
      const second = state.seconds
      return t('76177', { time: second })
    }

    watch(
      () => state.status,
      (val) => {
        if (['success', 'fail'].includes(val)) {
          countingDown()
        }
      }
    )

    onBeforeMount(() => {
      const token = route.query?.token
      if (!token) {
        state.status = 'fail'
        return
      }
      state.tokenInfo.token = token
      verifyToken()
    })

    // 倒计时
    const countingDown = () => {
      if (state.seconds > 0) {
        setTimeout(() => {
          state.seconds--
          countingDown()
        }, 1000)
      } else {
        if (state.status === 'success') {
          router.push({
            name: 'setPassword',
            query: state.tokenInfo
          })
        } else {
          router.push({ name: 'login' })
        }
      }
    }

    // 验证token
    const verifyToken = () => {
      get(
        URLS.verifyToken,
        {
          token: state.tokenInfo.token
        },
        false
      )
        .then((res: any) => {
          Object.assign(state.tokenInfo, res)
          state.status = 'success'
        })
        .catch(() => {
          state.status = 'fail'
        })
    }
    return {
      ...toRefs(state),
      verifyDes: computed(() => getVerifyDes())
    }
  }
})
