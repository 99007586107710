<template>
  <svg :class="['klook-svg', `klook-svg_${type}`, $options.class]" :style="styles" @click.prevent="$emit('click')">
    <use :xlink:href="`#${type}`" />
  </svg>
</template>

<script name="KlookIcon">
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    type: String,
    // eslint-disable-next-line vue/require-prop-type-constructor
    size: [] | Number | String
  },
  setup(props) {
    return {
      styles: computed(() => {
        let result = {}

        if (Array.isArray(props.size)) {
          result = {
            width: `${props.size[0]}px`,
            height: `${props.size[1]}px`
          }
        } else {
          result = {
            width: `${props.size || 16}px`,
            height: `${props.size || 16}px`
          }
        }

        return result
      })
    }
  }
})
</script>

<style lang="less" scoped>
.klook-svg {
  vertical-align: text-bottom;
}
</style>
